// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import HelloSign from 'hellosign-embedded';

// Create the HelloSign Embedded instance.
// Only do this once!

let STAGING_XOOS_CLIENT_ID = '405c1a2f89b4d19a4d644975fee95d7d'
let XOOS_CLIENT_ID = 'd0718b90667939761766014e0d6dd4c8'

let DOMAIN_LOCALHOST = 'localhost'
let DOMAIN_XOOS = 'xoos.co'
let DOMAIN_STAGING_XOOS = 'staging.xoos.co'

let user = undefined

let local = new RegExp(DOMAIN_LOCALHOST);
let staging = new RegExp(DOMAIN_STAGING_XOOS);
let live = new RegExp(DOMAIN_XOOS);


if (local.test(window.location.href) || staging.test(window.location.href)) {
    user = STAGING_XOOS_CLIENT_ID
} else if (live.test(window.location.href)) {
    user = XOOS_CLIENT_ID
}

window.client = new HelloSign({
    clientId: user
});








